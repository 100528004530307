import React, { FunctionComponent } from "react"
import Dotdotdot from "react-dotdotdot"
import { timeago } from "../../lib/DateHelper"
import { VivaFeedItem } from "."
import ProfileImage from "./ProfileImage"

export interface FeedItemProps {
  item: VivaFeedItem
}

const FeedItem: FunctionComponent<FeedItemProps> = ({item}) => {
  return (
    <a className="feed__item" href={item.url} target="_blank" rel="noopener noreferrer">
      <div className="feed__image">
        <picture>
          <ProfileImage src={item.profileImage} alt="Profile" />
        </picture>
      </div>
      <div className="feed__content">
        <div className="feed__content-section">
          <p className="feed__title">
            {item.name}
            <time className="feed__datetime feed__datetime--short"> - {timeago(item.date, true)}</time>
          </p>
          <div className="feed__text">
            <Dotdotdot clamp={2} >
              {item.text.substring(0, 190)}
            </Dotdotdot>
          </div>
        </div>
        <time className="feed__datetime feed__datetime--long" dateTime={item.date.toISOString()}>
          {timeago(item.date)}
        </time>
      </div>
    </a>
  )
}

export default FeedItem