export interface MessageBody {
  parsed: string
  plain: string
  rich: string
}

export interface UserLike {
  full_name: string
  permalink: string
  user_id: number
  network_id: number
}

export interface LikedBy {
  count: number
  names: any[]
}

export interface ImageInfo {
  url: string
  size: number
  thumbnail_url: string
}

export interface ImageAttachment {
  id: number
  network_id: number
  url: string
  web_url: string
  type: 'image'
  name: string
  original_name: string
  full_name: string
  description: string
  content_type: string
  content_class: string
  created_at: string
  owner_id: number
  official: boolean
  storage_type: string
  target_type: string
  storage_state?: any
  sharepoint_id?: any
  sharepoint_web_url?: any
  small_icon_url: string
  large_icon_url: string
  download_url: string
  thumbnail_url: string
  preview_url: string
  large_preview_url: string
  size: number
  owner_type: string
  last_uploaded_at: string
  last_uploaded_by_id: number
  last_uploaded_by_type: string
  uuid?: any
  transcoded?: any
  streaming_url?: any
  path: string
  y_id: number
  overlay_url: string
  privacy: string
  group_id: number
  height: number
  width: number
  scaled_url: string
  image: ImageInfo
  latest_version_id: number
  status: string
  real_type: string
}

export interface PraiseAttachment {
  type: 'praise'
  id: number
  network_id: number
  name: string
  full_name: string
  description: string
  icon: string
  comment: string
  web_url: string
  url: string
  praised_user_ids: number[]
}

export interface VivaMessage {
  id: number
  sender_id: number
  replied_to_id: number
  created_at: string
  network_id: number
  message_type: string
  sender_type: string
  url: string
  web_url: string
  group_id: number
  body: MessageBody
  thread_id: number
  client_type: string
  client_url: string
  system_message: boolean
  direct_message: boolean
  chat_client_sequence?: any
  language: string
  notified_user_ids: number[]
  privacy: string
  attachments: (ImageAttachment | PraiseAttachment)[]
  liked_by: LikedBy
  content_excerpt: string
  group_created_id: number
}

export interface UserReference {
  type: 'user'
  id: number
  name: string
  state: string
  full_name: string
  job_title: string
  network_id: number
  mugshot_url: string
  mugshot_url_template: string
  url: string
  web_url: string
  activated_at: string
  auto_activated: boolean
  stats: {
    following: number
    followers: number
    updates: number
  }
  email: string
}

export function isUserReference (reference: Reference): reference is UserReference {
  return (reference as UserReference).type === 'user'
}

export interface TagReference {
  type: 'tag'
  id: number
  network_id: number
  name: string
  url: string
  web_url: string
}

export interface ThreadReference {
  url: 'thread'
  web_url: string
  type: string
  id: number
  network_id: number
  thread_starter_id: number
  group_id: number
  topics: {
    id: number
    type: string
  }[]
  privacy: string
  announcement: boolean
  direct_message: boolean
  has_attachments: boolean
  reply_disabled: boolean
  stats: {
    first_reply_id: number
    first_reply_at: string
    latest_reply_id: number
    latest_reply_at: string
    updates: number
    shares: number
  }
  invited_user_ids: any[]
  read_only: boolean
}

export interface MessageReference {
  type: 'message'
  url: string
  web_url: string
  id: number
  sender_id: number
  replied_to_id: number
  created_at: string
  network_id: number
  group_id: number
  sender_type: string
  thread_id: number
  message_type: string
  system_message: boolean
  content_excerpt: string
  language: string
  privacy: string
  direct_message: boolean
  body: {
    plain: string
  }
}

export interface GroupReference {
  type: string
  id: number
  email: string
  full_name: string
  network_id: number
  name: string
  description: string
  privacy: string
  url: string
  web_url: string
  mugshot_url: string
  mugshot_url_template: string
  mugshot_id?: any
  show_in_directory: string
  created_at: string
  members: number
  aad_guests: number
  color: string
  external: boolean
  moderated: boolean
  header_image_url: string
  category: string
  default_thread_starter_type: string
  restricted_posting: boolean
  company_group: boolean
}

export interface TopicReference {
  type: string
  id: number
  network_id: number
  name: string
  normalized_name: string
  permalink: string
  url: string
  web_url: string
}

export type Reference = UserReference | TagReference | ThreadReference | MessageReference | GroupReference | TopicReference

export interface VivaFeed {
  threaded_extended: any,
  messages: VivaMessage[],
  references: Reference[],
  external_references: Reference[],
  meta: {
    older_available: boolean
    requested_poll_interval: number
    realtime: {
      uri: string
      authentication_token: string
      channel_id: string
    }
    last_seen_message_id: number
    unseen_message_count_following: number
    unseen_thread_count_following: number
    unseen_message_count_received: number
    unseen_thread_count_received: number
    unseen_message_count_algo: number
    unseen_thread_count_algo: number
    unseen_message_count_my_all: number
    unseen_thread_count_my_all: number
    current_user_id: number
    liked_message_ids: number[]
    followed_references: any[]
    ymodules: any[]
    newest_message_details: {
      id: number
      thread_id: number
      thread_starter: boolean
    },
    feed_name: string
    feed_desc: string
    direct_from_body: boolean
  }
}