import React, { FunctionComponent, useState } from "react"
import { useSettings } from "../SettingsProvider"
import { getThemeLabel, Themes, ThemeKey } from "../../themes/Themes"
import { AppList } from "./AppList"
import { AppListItem } from "../../lib/Apps"
import './style.scss'

export const Dialog: FunctionComponent = () => {
  const settings = useSettings()
  const [themeDropdownOpen, setThemeDropdownOpen] = useState(false)
  const [previewDropdownOpen, setPreviewDropdownOpen] = useState(false)

  const handleThemeDropdownEvent = (state: boolean) => (event: any) => {
    event.preventDefault()
    setThemeDropdownOpen(state)
  }

  const handlePreviewDropdownEvent = (state: boolean) => (event: any) => {
    event.preventDefault()
    setPreviewDropdownOpen(state)
  }

  const toggleApp = (appKey: string) =>
    settings.setAppHidden(appKey, !settings.isAppHidden(appKey))

  const reorderApps = (result: AppListItem[]) =>
    settings.setAppOrder(result.map(x => x.key))

  if (!settings.settingsScreenOpen)
    return null

  return (
    <div className="settings">
      <div className="settings__content">
        <div className="settings__header">
          <p className="settings__title">Settings</p>
          <button className="settings__close" onClick={() => settings.close()}>x</button>
        </div>
        <div className="settings__section settings__section--theme">
          <p className="settings__title">Theme</p>
          <button
            className="settings__select"
            onClick={handleThemeDropdownEvent(true)}
            onBlur={handleThemeDropdownEvent(false)}
            aria-haspopup="listbox">
              {getThemeLabel(settings.currentTheme)}
          </button>
          <ul role="listbox" aria-expanded={themeDropdownOpen} className="dropdown settings__theme-dropdown">
            {Object.entries(Themes).map(([key, theme]) => (
              <li key={key}
                  role="option"
                  aria-selected={key === settings.currentTheme}
                  onClick={() => settings.setCurrentTheme(key as ThemeKey)}>
                {theme.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="settings__section settings__section--theme">
          <p className="settings__title">Preview Features</p>
          <button
            className="settings__select"
            onClick={handlePreviewDropdownEvent(true)}
            onBlur={handlePreviewDropdownEvent(false)}
            aria-haspopup="listbox">
              {settings.enablePreviewFeatures ? 'Enabled' : 'Disabled'}
          </button>
          <ul role="listbox" aria-expanded={previewDropdownOpen} className="dropdown settings__theme-dropdown">
            <li key="disabled"
                role="option"
                aria-selected={!settings.enablePreviewFeatures}
                onClick={() => settings.setEnablePreviewFeatures(false)}>
              Disabled
            </li>
            <li key="enabled"
                role="option"
                aria-selected={settings.enablePreviewFeatures}
                onClick={() => settings.setEnablePreviewFeatures(true)}>
              Enabled
            </li>
          </ul>
        </div>
        <div className="settings__section settings__section--apps">
          <p className="settings__title">Apps</p>

          <ul className="settings__applist">
            <AppList
              apps={settings.apps}
              isAppHidden={settings.isAppHidden}
              toggleApp={toggleApp}
              onReorder={reorderApps} />
          </ul>
        </div>
      </div>
    </div>
  )
}

/**
 * Toggles overflow hidden on outer content and sets theme class.
 */
export const Wrapper: FunctionComponent = ({children}) => {
  const { settingsScreenOpen, currentTheme } = useSettings()

  const classes = [
    'settings-wrapper',
    (settingsScreenOpen ? ' settings-wrapper--is-open' : ''),
    'app-theme--' + currentTheme
  ]

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}