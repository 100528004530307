const localStorageAvailable = !!localStorage

let userKey: string | null = null

type SettingsMeta = {
  _version: number,
  _timestamp: number
}

export enum Keys {
  Settings = "sageHome.settings",
  ProfileInfo = "sageHome.profileInfo"
}

export const setUserKey = (newUserKey?: string | null) => {
  userKey = newUserKey ?? null
}

export const resetUserKey = () => {
  userKey = null
}

export const getItemKey = (key: string) =>
  `sage::${userKey}::${key}`

export const set = <T>(key: string, settings: T): void => {
  if (!localStorageAvailable || !userKey)
    return

  const settingsToStore: SettingsMeta & T = {
    ...settings,

    _version: 1,
    _timestamp: Date.now()
  }

  try {
    localStorage.setItem(getItemKey(key), JSON.stringify(settingsToStore))
  } catch (e) {
    console.error('Failed to update localStorage', e)
  }
}

export const get = <T>(key: string): T | null => {
  if (!localStorageAvailable || !userKey)
    return null

  try {
    const data = localStorage.getItem(getItemKey(key))

    if (!data)
      return null

    return JSON.parse(data) as T
  } catch (e) {
    console.error('Failed to retrieve localStorage', e)
    return null
  }
}