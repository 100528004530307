import React, { FunctionComponent } from "react"
import logoDarkText from '../../images/logo_dark.svg'
import logoWhiteText from '../../images/logo_white.svg'
import './style.scss'

export interface TopLogoProps {
  className?: string
}

const TopLogo: FunctionComponent<TopLogoProps> = ({className}) => {
  return (
    <div className={"top-logo " + (className ?? '')}>
      <picture>
        <img src={logoDarkText} className="top-logo__img top-logo__img--dark" alt="SAGE Logo" />
        <img src={logoWhiteText} className="top-logo__img top-logo__img--light" alt="SAGE Logo" />
      </picture>
    </div>
  )
}

export default TopLogo