import * as Msal from "@azure/msal-browser"

export type GlobalConfiguration = {
  confluenceUrl: string,
  vivaApiUrl: string,
  vivaFeedGroupId: number,
  msal: Msal.Configuration,
  authScopes: {
    graphApi: string[],
    viva: string[]
  },
  SAGEbot:{
    tokenURL: string
  }
}

export const config: GlobalConfiguration = require('./local.settings.json')

export default config