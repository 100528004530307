import React from "react"

type ErrorScreenState = {
  error: Error | null,
  errorInfo: React.ErrorInfo | null
}

export class ErrorScreen extends React.Component<{}, ErrorScreenState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorScreen