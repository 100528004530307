import React, { FunctionComponent, useState } from "react";
import { useAuth } from "../AuthProvider";
import { useInterval } from "../../lib/useInterval";
import Holidays from "date-holidays";
import holidayGreetings from "./data.json";
import "./style.scss";

// https://github.com/commenthol/date-holidays
const holidayLib = new Holidays("AU");
holidayLib.setLanguages("en");

// Add custom holidays
holidayGreetings.data.forEach((holiday) => {
  // Only custom holidays have a rule
  if (!holiday.rule) return;

  holidayLib.setHoliday(holiday.rule, {
    type: "observance",
    name: { en: holiday.name },
  });
});

const getGreetingMessage = (firstName: string): JSX.Element => {
  const now = new Date();
  const hour = now.getHours();
  const holiday = holidayLib.isHoliday(now);

  // If date is a holiday, get message
  if (holiday) {
    for (const item of holidayGreetings.data) {
      if (holiday.name === item.name) {
        return <>{item.message}</>;
      }
    }
  }

  let period = "Morning";

  if (hour >= 21) {
    period = "Night";
  } else if (hour >= 18) {
    period = "Evening";
  } else if (hour >= 12) {
    period = "Afternoon";
  }

  return (
    <>
      Good {period}, <strong>{firstName}</strong>
    </>
  );
};

export interface GreetingProps {
  className?: string;
}

const Greeting: FunctionComponent<GreetingProps> = ({ className }) => {
  const { profile } = useAuth();
  const firstName = profile?.firstName ?? "";
  const [greeting, setGreeting] = useState(getGreetingMessage(firstName));

  // Recheck if greeting has changed every 5 mins
  useInterval(() => {
    const newGreeting = getGreetingMessage(firstName);

    if (newGreeting !== greeting) {
      setGreeting(newGreeting);
    }
  }, 5 * 60 * 1000);

  if (!profile) return null;

  return <div className={"greeting " + (className ?? "")}>{greeting}</div>;
};

export default Greeting;
