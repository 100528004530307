import React, { FunctionComponent, useState, useEffect } from "react";
import "./style.scss";

const Carousel: FunctionComponent<{
  slides: JSX.Element[];
}> = (props) => {
  const [current, setCurrent] = useState(0);
  const length = props.slides.length;

  useEffect(() => {
    const interval = 6000;

    let timer = setInterval(async () => {
      nextSlide();
    }, interval);

    const banner = document.getElementById("banner");

    // pause the interval on mouse enter, unpause on exit
    if (banner) {
      banner.addEventListener("mouseenter", () => {
        clearInterval(timer);
      });
      banner.addEventListener("mouseleave", () => {
        timer = setInterval(async () => {
          nextSlide();
        }, interval);
      });
    }

    return () => clearInterval(timer);
  });

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  return (
    <div className="slider">
      {props.slides.map((slide, index) => {
        return <div key={index}> {index === current && slide}</div>;
      })}
      <div className="slider_buttons">
        {props.slides.map((slide, index) => {
          return (
            <button
              className="slider_button"
              onClick={() => {
                setCurrent(index);
              }}
              disabled={current === index ? true : false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
