import React, { FunctionComponent } from "react";
import TopLogo from "../TopLogo";
import Greeting from "../Greeting";
import GlobalNav from "../GlobalNav";
import VivaFeed from "../VivaFeed";
import Search from "../Search";
import Banner from "../Banner";
import AppGrid from "../AppGrid";
import * as SettingsScreen from "../SettingsScreen";
import MinimizableWebChat from "../SAGE-bot";
import TopBanner from "../TopBanner";

const DashboardScreen: FunctionComponent = () => {
  return (
    <>
      <SettingsScreen.Dialog />
      <TopLogo className="screen-transition-target" />
      <TopBanner hidden={true} />
      <header className="top-header">
        <Greeting className="screen-transition-target" />
        <GlobalNav className="screen-transition-target" />
      </header>
      <div className="section section--two-cols section--top screen-transition-target">
        <div className="section__widget section__widget--viva">
          <p className="section__title">Viva Engage</p>
          <VivaFeed />
        </div>
        <div className="section__widget">
          <div className="section__widget--search">
            <p className="section__title">Search</p>
            <Search />
          </div>
          <div className="section__widget--banner">
            <Banner />
          </div>
        </div>
      </div>
      <div className="section section--apps screen-transition-target">
        <p className="section__title">Apps</p>
        <AppGrid />
      </div>
      <div className="SAGE-bot">
        <MinimizableWebChat />
      </div>
    </>
  );
};

export default DashboardScreen;
