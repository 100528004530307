import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import * as BrowserInfo from "./lib/BrowserInfo";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

import "./fonts/sofia-pro/stylesheet.css";
import "./index.scss";

// Internet Explorer detection
document.body.classList.add(BrowserInfo.isIE ? "ie" : "not-ie");
document.body.classList.add(BrowserInfo.isEdge ? "edge" : "not-edge");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// Allow app to work offline and load faster
// See: https://create-react-app.dev/docs/making-a-progressive-web-app/
serviceWorker.register();
