import React, { FunctionComponent, useState, useEffect } from "react"
import { useInterval } from "../../lib/useInterval"
import './style.scss'

interface RedirectScreenProps {
  url: string,
  waitTime?: number
}

const RedirectScreen: FunctionComponent<RedirectScreenProps> = ({url, waitTime}) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(waitTime ?? 10)

  useInterval(() => {
    setTimeRemaining(Math.max(0, timeRemaining - 1))
  }, 1000)

  useEffect(() => {
    if (timeRemaining <= 0) {
      window.location.href = url
    }
  }, [timeRemaining, url])

  return (
    <div className="redirect">
      <div className="redirect__content">
        <p className="redirect__title">You are being redirected</p>
        <p className="redirect__text">
          It appears you are trying to access a page that has been moved.
          Please update your bookmarks accordingly. You will be redirected automatically.
        </p>
        <a className="button redirect__button" href={url}>
          Continue ({timeRemaining})
        </a>
      </div>
    </div>
  )
}

export default RedirectScreen