import React, { FunctionComponent, useState } from "react"
import './style.scss'

const searchEngines = {
  confluence: {
    label: 'Confluence',
    url: 'https://gotosage.atlassian.net/wiki/search?text='
  },
  projectWorkspace: {
    label: 'Project Workspace',
    url: 'https://projects.sageautomation.com/search/Pages/results.aspx?k='
  },
  sharepoint: {
    label: 'SharePoint',
    url: 'https://gotosage.sharepoint.com/_layouts/15/sharepoint.aspx?v=search&q='
  }
}

type SearchEngine = keyof typeof searchEngines

const Search: FunctionComponent = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchType, setSearchType] = useState<SearchEngine>('confluence')
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const selectItem = (searchType: SearchEngine) => {
    setDropdownOpen(false)
    setSearchType(searchType)
  }

  const doSearch = (event: React.FormEvent) => {
    event.preventDefault()
    window.location.href = searchEngines[searchType].url + encodeURIComponent(searchTerm)
  }

  const handleDropdownEvent = (state: boolean) => (event: any) => {
    event.preventDefault()
    setDropdownOpen(state)
  }

  const currentLabel = searchEngines[searchType].label

  return (
    <form onSubmit={doSearch}>
      <div className="search">
        <input
          role="search"
          className="search__input"
          type="text"
          name="query"
          placeholder={`Search ${currentLabel}...`}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)} />

        <div className="search__actions">
          <div className="search__engine">
            <button
              type="button"
              onClick={handleDropdownEvent(true)}
              onBlur={handleDropdownEvent(false)}
              aria-haspopup="listbox">
              {currentLabel}
            </button>
          </div>

          <div className="search__submit">
            <button type="submit">Search</button>
          </div>
        </div>

        <ul role="listbox" aria-expanded={dropdownOpen} className="dropdown search__engine-dropdown">
          {Object.entries(searchEngines).map(([key, engine]) => (
            <li key={key}
                role="option"
                aria-selected={key === searchType}
                onClick={() => selectItem(key as SearchEngine)}>
              {engine.label}
            </li>
          ))}
        </ul>
      </div>
    </form>
  )
}

export default Search