import React, { createContext, useContext, useState, FunctionComponent, useEffect } from 'react'
import { AppListItem, defaultApps } from '../../lib/Apps'
import { ThemeKey } from '../../themes/Themes'
import * as Storage from '../../lib/Storage'
import { useAuth } from '../AuthProvider'

const preferDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

const initialSettings: SettingsData = {
  appOrder: [],
  hiddenApps: [],
  currentTheme: preferDark ? 'sagedark' : 'sagelight',
  enablePreviewFeatures: false
}

/**
 * Represents core settings that are persisted
 * between page loads.
 */
export interface SettingsData {
  appOrder: string[],
  hiddenApps: string[],
  currentTheme: ThemeKey,
  enablePreviewFeatures: boolean
}

/**
 * Settings context that is returned when calling
 * useSettings(). For properties that need to be
 * saved see SettingsData interface.
 */
export interface SettingsContext extends SettingsData {
  apps: AppListItem[],
  settingsScreenOpen: boolean,
  enablePreviewFeatures: boolean,

  isAppHidden: (appKey: string) => boolean,
  setAppHidden: (appKey: string, state: boolean) => void,
  setCurrentTheme: (theme: ThemeKey) => void,
  setEnablePreviewFeatures: (flag: boolean) => void,
  setAppOrder: (newOrder: string[]) => void,

  open: () => void,
  close: () => void
}

export const SettingsCtxComponent = createContext<SettingsContext>({} as any)

const SettingsProvider: FunctionComponent = ({ children }) => {
  const settings = useProvideSettings()

  return (
    <SettingsCtxComponent.Provider value={settings}>
      {children}
    </SettingsCtxComponent.Provider>
  )
}

export const useSettings = () => {
  return useContext(SettingsCtxComponent)
}

const sortApps = (apps: AppListItem[], appOrder: string[]) => {
  return Array.from(apps).sort((a, b) => {
    let aIdx = appOrder.indexOf(a.key)
    let bIdx = appOrder.indexOf(b.key)

    // Index will be -1 if not found so we need to
    // handle this by setting the index to a higher
    // number.
    if (aIdx < 0)
      aIdx = defaultApps.findIndex(x => x.key === a.key)

    if (bIdx < 0)
      bIdx = defaultApps.findIndex(x => x.key === b.key)

    return aIdx > bIdx ? 1 : -1
  })
}

const useProvideSettings = (): SettingsContext => {
  const auth = useAuth()

  const [appOrder, setAppOrder] = useState<string[]>(initialSettings.appOrder)
  const [hiddenApps, setHiddenApps] = useState<string[]>(initialSettings.hiddenApps)
  const [currentTheme, setCurrentTheme] = useState<ThemeKey>(initialSettings.currentTheme)
  const [enablePreviewFeatures, setEnablePreviewFeatures] = useState(initialSettings.enablePreviewFeatures)
  const [settingsScreenOpen, setSettingsScreenOpen] = useState(false)

  const isAppHidden = (appKey: string) => hiddenApps.includes(appKey)

  const setAppHidden = (appKey: string, state: boolean) => {
    if (state && !isAppHidden(appKey)) {
      setHiddenApps([...hiddenApps, appKey])
    } else {
      setHiddenApps(hiddenApps.filter(val => val !== appKey))
    }
  }

  const open = () => setSettingsScreenOpen(true)
  const close = () => setSettingsScreenOpen(false)

  const apps = React.useMemo(() => sortApps(defaultApps, appOrder), [appOrder])

  // Persist settings when they change.
  useEffect(() => {
    Storage.set<SettingsData>(Storage.Keys.Settings, {
      appOrder,
      hiddenApps,
      currentTheme,
      enablePreviewFeatures
    })
  }, [appOrder, hiddenApps, currentTheme, enablePreviewFeatures])

  // Update settings when user profile changes.
  useEffect(() => {
    if (!auth.profile) {
      setAppOrder(initialSettings.appOrder)
      setHiddenApps(initialSettings.hiddenApps)
      setCurrentTheme(initialSettings.currentTheme)
    } else {
      const settings = Storage.get<SettingsData>(Storage.Keys.Settings)

      setAppOrder(settings?.appOrder ?? initialSettings.appOrder)
      setHiddenApps(settings?.hiddenApps ?? initialSettings.hiddenApps)
      setCurrentTheme(settings?.currentTheme ?? initialSettings?.currentTheme)
      setEnablePreviewFeatures(settings?.enablePreviewFeatures ?? initialSettings.enablePreviewFeatures)
    }
  }, [auth.profile])

  return {
    apps,
    appOrder,
    hiddenApps,
    currentTheme,
    settingsScreenOpen,
    enablePreviewFeatures,

    isAppHidden,
    setAppHidden,
    setCurrentTheme,
    setEnablePreviewFeatures,
    setAppOrder,
    open,
    close
  }
}

export default SettingsProvider