import * as auth from "./Auth"
import createGraphClient from "./MsGraphApi"

export type UserProfileInfo = {
  userId: string,
  fullName: string
  firstName: string
  lastName: string
  initals: string
  avatarUrl: string | null
}

const getGraphApiClient = async () => {
  const accessToken = await auth.getAccessToken('graphApi')
  return accessToken ? createGraphClient(accessToken) : null
}

export const getProfileInfo = async () : Promise<UserProfileInfo | null> => {
  const graphClient = await getGraphApiClient()

  if (!graphClient) {
    return null
  }

  const profile = await graphClient.getCurrentUserInfo()

  let profileImageUrl = null

  try {
    profileImageUrl = await graphClient.getCurrentUserPhotoDataUrl(96)
  } catch (e) {
    console.log('Could not load profile image', e)
  }

  const givenName = profile.givenName ?? ''
  const surname = profile.surname ?? ''

  return {
    userId: profile.id,
    firstName: givenName,
    lastName: surname,
    fullName: profile.displayName ?? '',
    initals: givenName[0] + ' ' + surname[0],
    avatarUrl: profileImageUrl
  }
}