import React, { FunctionComponent, useCallback, useState } from 'react';

import './style.scss'
import './';
import WebChat from './Webchat';
import config from './../../Config';
import { useSettings } from '../SettingsProvider';

const MinimizableWebChat: FunctionComponent = () => {
  const settings = useSettings()
  const [loaded, setLoaded] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [side, setSide] = useState('right');
  const [token, setToken] = useState();

  // To learn about reconnecting to a conversation, see the following documentation:
  // https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-reconnect-to-conversation?view=azure-bot-service-4.0
  const handleFetchToken = useCallback(async () => {
    if (!token) {
      const res = await fetch( config.SAGEbot.tokenURL, { method: 'POST' });
      const result = await res.json();
      console.log(res);
      const token = result.token.token;
      setToken(token);
    }
  }, [setToken, token]);

  const handleMaximizeButtonClick = useCallback(async () => {
    setLoaded(true);
    setMinimized(false);
  }, [setMinimized]);

  const handleMinimizeButtonClick = useCallback(() => {
    setMinimized(true);
  }, [setMinimized]);

  const handleSwitchButtonClick = useCallback(() => {
    setSide(side === 'left' ? 'right' : 'left');
  }, [setSide, side]);

  // TODO: [P2] Currently, we cannot unmount Web Chat from DOM when it is minimized.
  //       Today, if we unmount it, Web Chat will call disconnect on DirectLineJS object.
  //       When minimized, we still want to maintain that connection while the UI is gone.
  //       This is related to https://github.com/microsoft/BotFramework-WebChat/issues/2750.

  if (!settings.enablePreviewFeatures) {
    return null
  }

  return (
    <div className="minimizable-web-chat">
      {minimized && (
        <button className="maximize" onClick={handleMaximizeButtonClick}></button>
      )}
      {loaded && (
        <div className={[side === 'left' ? 'chat-box left' : 'chat-box right', minimized ? 'hide' : ''].join(' ')}>
          <header>
            <div className="filler" />
            <button className="switch" onClick={handleSwitchButtonClick}>
              <span className="ms-Icon ms-Icon--Switch" />
            </button>
            <button className="minimize" onClick={handleMinimizeButtonClick}>
              <span className="ms-Icon ms-Icon--ChromeMinimize" />
            </button>
          </header>
          <WebChat
            className="react-web-chat"
            onFetchToken={handleFetchToken}
            token={token}
          />
        </div>
      )}
    </div>
  );
};

export default MinimizableWebChat;
