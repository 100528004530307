import React, { FunctionComponent } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useAuth } from '../AuthProvider'
import './style.scss'

const LoginScreen: FunctionComponent = () => {
  const auth = useAuth()
  const nodeRef = React.useRef(null)

  return (
    <CSSTransition nodeRef={nodeRef} in={auth.isChecking} timeout={1000} classNames="loading">
      <div ref={nodeRef} className="login loading-enter-done">
        <div className="login__content">
          <p className="login__title">Welcome</p>
          <p className="login__text">To continue please login.</p>
          <button className="login__button" onClick={() => auth.login()}>LOGIN</button>
        </div>
      </div>
    </CSSTransition>
  )
}

export default LoginScreen