import React, { FunctionComponent, useState, useEffect } from "react"
import { getAccessToken } from "../../lib/Auth"
import { getGroupFeed } from "../../lib/VivaApi"
import { isUserReference, UserReference, VivaMessage } from "../../lib/VivaTypes"
import FeedItem from "./FeedItem"
import PlaceholderFeedItem from "./PlaceholderFeedItem"
import config from "../../Config"
import './style.scss'

const GROUP_TO_DISPLAY = config.vivaFeedGroupId

export type VivaFeedItem = {
  name: string,
  profileImage: string,
  url: string,
  text: string,
  date: Date
}

const convertApiItem = (item: VivaMessage, user: UserReference | null) => {
  let text = item.content_excerpt

  if (item.attachments.some(a => a.type === 'praise')) {
    const attachment = item.attachments.find(a => a.type === 'praise')
    text = (attachment?.description ?? text) + ' 👍'
  }

  return {
    name: user?.full_name ?? 'Unknown',
    date: new Date(Date.parse(item.created_at)),
    profileImage: user?.mugshot_url ?? '',
    url: item.web_url,
    text
  }
}

const VivaFeed: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [feedItems, setFeedItems] = useState<VivaFeedItem[]>([])

  useEffect(() => {
    (async function() {
      setIsLoading(true)

      try {
        const token = await getAccessToken('viva')

        if (!token)
          throw new Error('Could not retrieve viva engage access token')

        const result = await getGroupFeed(token, GROUP_TO_DISPLAY, { limit: 4, threaded: true })

        const findUser = (id: number) =>
          result.references.find(ref => isUserReference(ref) && ref.id === id) as UserReference | null

        setFeedItems(result.messages.map(item =>
        convertApiItem(item, findUser(item.sender_id))))
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  return (
    <div className="feed">
      {feedItems.map((item, key) => (
        <FeedItem key={key} item={item} />
      ))}

      {(feedItems.length > 0 && !isLoading) &&
        <a className="feed__more" href="https://engage.cloud.microsoft/main/org/gotosage.com/feed">View More</a>}

      {(feedItems.length === 0 && !isLoading) &&
        <div className="feed__nothing">Nothing to see here...move along...</div>}

      {isLoading &&
        <div className="feed__loading">
          <PlaceholderFeedItem key={1} />
          <PlaceholderFeedItem key={2} />
          <PlaceholderFeedItem key={3} />
          <PlaceholderFeedItem key={4} />
        </div>}
    </div>
  )
}

export default VivaFeed