import React, { FunctionComponent } from "react";
import Carousel from "../Carousel";
import banners from "./banners";
import "./style.scss";

const Banner: FunctionComponent = () => {
  var slides = banners.map((banner) => {
    return (
      <div className="banner" id="banner">
        <div className="banner__content legend">
          <p className="banner__paragraph">
            {banner.icon && (
              <img
                className="banner__image"
                src={banner.icon}
                alt={banner.icon_alt ? banner.icon_alt : "banner"}
              />
            )}
          </p>
          <p className="banner__text">{banner.text}</p>
          {banner.links && 
            <div className="buttons">
              {banner.links.map((link)=> {return (
                <a
                  className="button banner__button  "
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {banner.link_texts && banner.links && banner.link_texts[banner.links.indexOf(link)]}
                </a>
              )})}
          </div>}
        </div>
      </div>
    );
  });

  return Carousel({ slides });
};
export default Banner;
