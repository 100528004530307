import React, { FunctionComponent, useState } from "react"

type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const ProfileImage: FunctionComponent<ImageProps> = (props) => {
  const [failed, setFailed] = useState(false)

  return <img
    {...props}
    alt={props.alt}
    data-load-failed={failed}
    onError={() => setFailed(true)} />
}

export default ProfileImage