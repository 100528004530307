import { VivaFeed } from "./VivaTypes"
import config from "../Config"

const apiUrl = config.vivaApiUrl

export type FeedQueryParams = {
  older_than?: number,
  newer_than?: number,
  threaded?: boolean,
  limit?: number
}

export const getAlgorithmicFeed = async (accessToken: string, params?: FeedQueryParams): Promise<VivaFeed> => {
  return await callVivaApi<VivaFeed>('/v1/messages/algo.json', accessToken, params)
}

export const getMyFeed = async (accessToken: string, params?: FeedQueryParams): Promise<VivaFeed> => {
  return await callVivaApi<VivaFeed>('/v1/messages/my_feed.json', accessToken, params)
}

export const getMessages = async (accessToken: string, params?: FeedQueryParams): Promise<VivaFeed> => {
  return await callVivaApi<VivaFeed>('/v1/messages.json', accessToken, params)
}

export const getGroupFeed = async (accessToken: string, groupId: number, params?: FeedQueryParams): Promise<VivaFeed> => {
  return await callVivaApi<VivaFeed>(`/v1/messages/in_group/${groupId}.json`, accessToken, params)
}

const queryParams = (obj: any): string => {
  return Object.keys(obj)
    .map(k => k + '=' + encodeURIComponent(obj[k]))
    .join('&')
}

export const callVivaApi = <TRes>(url: string, accessToken: string, params?: any): Promise<TRes> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText))
      } else {
        reject(xhr.statusText)
      }
    })

    xhr.addEventListener('error', () => reject(xhr.statusText))

    if (params)
      url += '?' + queryParams(params)

    xhr.open('GET', apiUrl + '/api' + url, true)

    // IE doesn't support reponseType json so we deal with that manually :(
    xhr.responseType = 'text'

    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)

    xhr.send()
  })
}