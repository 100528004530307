import React, { FunctionComponent } from "react"
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd"
import { AppListItem } from "../../lib/Apps"

export interface AppListProps {
  apps: AppListItem[],
  onReorder: (result: AppListItem[]) => void,
  isAppHidden: (appKey: string) => boolean,
  toggleApp: (appKey: string) => void
}

const reorder = (list: AppListItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

export const AppList: FunctionComponent<AppListProps> = ({ apps, onReorder, isAppHidden, toggleApp }) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination)
      return

    const newOrder = reorder(
      apps,
      result.source.index,
      result.destination.index
    )

    onReorder(newOrder)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="settings__applist"
          >
            {apps.map((item, index) => (
              <Draggable key={item.key} draggableId={item.key} index={index}>
                {(provided, snapshot) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={'settings__app' + (snapshot.isDragging ? ' settings__app--is-dragging' : '')}
                  >
                    <div
                      {...provided.dragHandleProps}
                      className="settings__app__handle"></div>

                    <div className="settings__app__label">{item.title}</div>

                    <button
                      className="settings__app__visibility"
                      data-visible={!isAppHidden(item.key)}
                      onClick={() => toggleApp(item.key)}></button>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}
