import React, { FunctionComponent } from "react"
import Skeleton from "../Skeleton"

const PlaceholderFeedItem: FunctionComponent = () => {
  return (
    <div className="feed__item feed__item--placeholder">
      <div className="feed__image"></div>
      <div className="feed__content">
        <div className="feed__content-section">
          <p className="feed__title">
            <Skeleton inline width={100} height={18} verticalMargin={2} />
          </p>
          <div className="feed__text">
            <Skeleton width="100%" height={12} verticalMargin={4} />
            <Skeleton width="85%" height={12} verticalMargin={4} />
          </div>
        </div>
        <span className="feed__datetime feed__datetime--long">
          <Skeleton width={70} height={12} />
        </span>
      </div>
    </div>
  )
}

export default PlaceholderFeedItem