import React, { FunctionComponent, useEffect, useMemo } from 'react';
import ReactWebChat, { createDirectLine, createStyleSet } from 'botframework-webchat';
import './style.scss'
import logo from './image/attempt2.png';
import { useAuth } from '../AuthProvider';


export interface webChatComponents {
  className: string,
  onFetchToken: () => void,
  token: string | undefined
}

const WebChat: FunctionComponent<webChatComponents> = ({ className, onFetchToken, token }) => {
  const { profile } = useAuth()
  const directLine = useMemo(() => createDirectLine({ token }), [token]);
  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent',
        botAvatarImage: logo,
        botAvatarInitials: 'B',
        userAvatarImage: profile?.avatarUrl ?? '',
        userAvatarBackgroundColor: 'rgb(103,124,135)',
        userAvatarInitials: 'U',
        emojiSet: true,
      }),
    [profile]
  );

  styleSet.textContent = {
    ...styleSet.textContent,
    fontFamily: "Sofia Pro, sans-serif",
    fontSize: '13px'
  }

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  return token ? (
    <ReactWebChat className={`${className || ''} web-chat`} directLine={directLine} styleSet={styleSet} />
  ) : (
    <div className={`${className || ''} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <span className="ms-Icon ms-Icon--Robot" />
        </div>
        <p>Please wait while we are connecting.</p>
      </div>
    </div>
  );
};

export default WebChat;
