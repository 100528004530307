export function timeago(date: number | Date, short: boolean = false) {
  date = +date

  let seconds = Math.floor((Date.now() - date) / 1000)
  let unit = short ? 's' : 'second'
  let direction = short ? '' : 'ago'
  let spacing = short ? '' : ' '

  if (seconds < 0) {
    seconds = -seconds
    direction = short ? '+' : 'from now'
  }

  let value = seconds

  if (seconds >= 31536000) {
    value = Math.floor(seconds / 31536000)
    unit = short ? 'y' : 'year'
  } else if (seconds >= 86400) {
    value = Math.floor(seconds / 86400)
    unit = short ? 'd' : 'day'
  } else if (seconds >= 3600) {
    value = Math.floor(seconds / 3600)
    unit = short ? 'h' : 'hour'
  } else if (seconds >= 60) {
    value = Math.floor(seconds / 60)
    unit = short ? 'm' : 'minute'
  }

  if (value !== 1 && !short)
    unit = unit + 's'

  return value + spacing + unit + spacing + direction
}