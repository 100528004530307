import React, { FunctionComponent } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { useAuth } from '../AuthProvider'
import LoginScreen from '../LoginScreen'
import DashboardScreen from './DashboardScreen'
import './style.scss'

const Dashboard: FunctionComponent = () => {
  const auth = useAuth()
  const screenRef = React.useRef(null)

  const showLoginModal = !auth.isAuthenticated || auth.isChecking
  const stateKey = showLoginModal ? 'login' : 'dashboard'

  return (
    <div className="main-content">
      <SwitchTransition>
        <CSSTransition nodeRef={screenRef} key={stateKey} in={showLoginModal} timeout={showLoginModal ? 400 : 2000} classNames="screen">
          <div ref={screenRef} className={`screen screen--${stateKey}`}>
            {showLoginModal
            ? <LoginScreen />
            : <DashboardScreen />}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default Dashboard
