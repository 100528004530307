import * as Msal from "@azure/msal-browser";
import { isIEOrEdge } from "./BrowserInfo";
import config from "../Config";

const msal = new Msal.PublicClientApplication({
  ...config.msal,
  cache: {
    ...config.msal.cache,
    storeAuthStateInCookie: isIEOrEdge,
  },
});

const scopes = config.authScopes;

let currentAccount: Msal.AccountInfo | null = null;

let tokens: Record<keyof typeof scopes, Msal.AuthenticationResult | null> = {
  graphApi: null,
  viva: null,
};

export const login = async () => {
  await msal.loginRedirect({
    scopes: ["openid", "profile", "User.Read"],
    extraScopesToConsent: [...scopes.graphApi, ...scopes.viva],
  });
};

export const logout = () => {
  msal.logout();
};

export const aquireToken = async (key: keyof typeof scopes): Promise<void> => {
  const account = getAccount();

  if (!account) {
    console.log("Cannot acquireToken when not logged in");
    return;
  }

  try {
    //console.log('Attempting acquireTokenSilent(' + key + ')...')

    tokens[key] = await msal.acquireTokenSilent({
      account: account,
      scopes: scopes[key],
    });

    // console.log('Success acquireTokenSilent(' + key + ')...')
  } catch (error) {
    //console.log('Failed to acquireTokenSilent', [error.name, error])

    if (error instanceof Msal.InteractionRequiredAuthError) {
      //console.log('Attempting acquireTokenRedirect(' + key + ')...')

      await msal.acquireTokenRedirect({ scopes: scopes[key] });
    } else {
      throw error;
    }
  }

  return;
};

export const getAccount = () => {
  if (currentAccount) return currentAccount;

  const currentAccounts = msal.getAllAccounts();

  //console.log("currentAccounts", currentAccounts);

  if (currentAccounts === null || !currentAccounts.length) {
    return null;
  } else {
    currentAccount = currentAccounts.find((acc) => !!acc.homeAccountId) ?? null;
    return currentAccount;
  }
};

export const getAccessToken = async (
  key: keyof typeof scopes
): Promise<string | null> => {
  await aquireToken(key);
  return tokens[key]?.accessToken ?? null;
};

export const checkForRedirectToken = async () => {
  if (!redirectPromise) {
    console.log("No handleRedirectPromise found");
    return;
  }

  //console.log("Checking for redirect token...");

  try {
    const tokenResponse = await redirectPromise;

    if (tokenResponse !== null) {
      //console.log("Redirect token found", tokenResponse);

      if (tokenResponse.scopes.includes(scopes.viva[0])) {
        tokens.viva = tokenResponse;
      } else {
        tokens.graphApi = tokenResponse;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const redirectPromise = msal.handleRedirectPromise();
