import React from 'react'
import { AuthProvider } from './components/AuthProvider'
import ErrorScreen from './components/ErrorScreen'
import Dashboard from './components/Dashboard'
import SettingsProvider from './components/SettingsProvider'
import * as SettingsScreen from './components/SettingsScreen'
import RedirectScreen from './components/RedirectScreen'
import config from './Config'
import { defaultApps } from './lib/Apps'

function App() {
  const path = window.location.pathname

  // Special redirect for IT service desk
  if (path === '/sd' || path === '/sd/') {
    const serviceDesk = defaultApps.find(app => app.key === 'it_service_desk')

    if (serviceDesk?.url) {
      return (
        <ErrorScreen>
          <RedirectScreen url={serviceDesk.url} waitTime={0} />
        </ErrorScreen>
      )
    }
  }

  // The old self-hosted Confluence site used to live
  // on the same url as this app. If someone visits
  // from a non-root url we assume that they have come
  // from an old Confluence url and redirect them to the
  // new Confluence url accordingly.
  if (path !== '/' && path !== '') {
    const confluenceUrl = config.confluenceUrl + path

    return (
      <ErrorScreen>
        <RedirectScreen url={confluenceUrl} />
      </ErrorScreen>
    )
  }

  return (
    <ErrorScreen>
      <AuthProvider>
        <SettingsProvider>
          <SettingsScreen.Wrapper>
            <div className="app">
              <Dashboard />
            </div>
          </SettingsScreen.Wrapper>
        </SettingsProvider>
      </AuthProvider>
    </ErrorScreen>
  )
}

export default App
