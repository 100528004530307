import React, { FunctionComponent } from "react";
import { useSettings } from "../SettingsProvider";
import "./style.scss";

const AppGrid: FunctionComponent = () => {
  const { apps, isAppHidden } = useSettings();

  const visibleApps = apps.filter((app) => !isAppHidden(app.key));

  return (
    <div className="app-grid">
      {visibleApps.map((item, key) => (
        <a key={key} className="app-grid__item app-card" href={item.url}>
          <div className="app-card__logo">
            <picture>
              <img src={item.icon} alt={item.title} />
            </picture>
          </div>
          <div className="app-card__content">
            <p className="app-card__title">{item.title}</p>
            <p className="app-card__description">{item.description}</p>
           
            {item.second_description ? (
              <div> <p className="app-card__description">{item.second_description}</p></div>
            ) : (
              ""
            )}
          </div>
        </a>
      ))}
    </div>
  );
};

export default AppGrid;
