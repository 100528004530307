import React, { FunctionComponent, useState } from "react"
import { useAuth } from "../AuthProvider"
import { useSettings } from "../SettingsProvider"
import ProfileImage from "../VivaFeed/ProfileImage"
import './style.scss'

const handleEvent = (fn: () => void) => (event: any) => {
  event.preventDefault()
  fn()
}

export interface GlobalNavProps {
  className?: string
}

const GlobalNav: FunctionComponent<GlobalNavProps> = ({className}) => {
  const settings = useSettings()
  const { profile, logout } = useAuth()
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false)

  return (
    <ul className={"global-nav " + (className ?? '')} role="navigation" aria-label="Main">
      <li>
        <button className="global-nav__settings" onClick={handleEvent(() => settings.open())}>
          <span className="icon icon--settings"></span>
        </button>
      </li>
      <li>
        <button
          className="global-nav__profile"
          onClick={handleEvent(() => setProfileDropdownOpen(true))}
          onBlur={handleEvent(() => setProfileDropdownOpen(false))}
          aria-haspopup="listbox">
          <picture>
            <ProfileImage src={profile?.avatarUrl ?? ''} alt="Profile" />
          </picture>
        </button>

        <ul role="listbox" aria-expanded={profileDropdownOpen} className="dropdown global-nav__profile__dropdown">
          <li onClick={() => logout()}>
            Sign out
          </li>
        </ul>
      </li>
    </ul>
  )
}

export default GlobalNav