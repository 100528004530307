// Additional theme styles
import './_sage-dark.scss'
import './_pipboy.scss'

export type ThemeKey = keyof typeof Themes

// Register theme
export const Themes = {
  'sagelight': {
    label: 'SAGE (Light)'
  },
  'sagedark': {
    label: 'SAGE (Dark)'
  },
  'pipboy': {
    label: 'Pipboy'
  }
}

export const getThemeLabel = (key: keyof typeof Themes): string => {
  return Themes[key]?.label ?? ''
}