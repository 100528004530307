import React, { FunctionComponent } from "react";
import "./style.scss";

const bannerIcon = "./ruokdayjpg.jpg";

const Banner: FunctionComponent<{ hidden: boolean }> = ({ hidden }) => {
  const bannerImage = require(`${bannerIcon}`);

  return (
    <div hidden={hidden}>
      <div className="top_banner">
        <div className="top_banner__content">
          <picture>
            <img src={bannerImage} alt="SAGE Home Banner" />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default Banner;
