import React, { FunctionComponent } from "react"
import './style.scss'

export interface SkeletonProps {
  inline?: boolean,
  width?: number | string,
  height?: number | string,
  verticalMargin?: number
}

const Skeleton: FunctionComponent<SkeletonProps> = ({inline = false, width = '100%', height = 16, verticalMargin = 0}) => {
  return (
    <span className={"skeleton" + (inline ? ' skeleton--inline' : '')} style={{width, height, margin: `${verticalMargin}px 0`}}></span>
  )
}

export default Skeleton