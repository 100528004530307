export type BannerListItem = {
  text: string;
  icon?: string;
  icon_alt?: string;
  links?: string[];
  link_texts?: string[];
};


// Link texts have to be in order of links
export const banners: BannerListItem[] = [
  {
    text: "Request Leave or Update Info in Workday. Explore Smarter Systems for Training Guides & More!",
    icon: require("../../images/banners/workday-logo.png"),
    icon_alt: "Workday arriving logo",
    links: ["https://wd3.myworkday.com/gotosage", 
    "https://gotosage.atlassian.net/wiki/spaces/SMARTSYS/pages/1607860522/Workday+Training+Guides","https://gotosage.atlassian.net/wiki/spaces/SMARTSYS/pages/1627160891/Change+Champions","https://gotosage.atlassian.net/wiki/spaces/SMARTSYS/pages/1627160751/Frequently+Asked+Questions+FAQ"],
    link_texts:["Visit Workday","View Guides","Change Champions","FAQ'S"]
  },
];

export default banners;
