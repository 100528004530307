export interface GraphApiUser {
  '@odata.context': string
  businessPhones: string[]
  displayName: string
  givenName: string | null
  jobTitle: string
  mail: string
  mobilePhone: string
  officeLocation: string
  preferredLanguage: string | null
  surname: string | null
  userPrincipalName: string
  id: string
}

export type PhotoSize =
  48  | 64  | 96  | 120 | 240 |
  360 | 432 | 504 | 648

export const createGraphClient = (accessToken: string) => {
  const getCurrentUserInfo = (): Promise<GraphApiUser> =>
    callGraphApi<GraphApiUser>('/v1.0/me/', accessToken)

  const getCurrentUserPhoto = (size: PhotoSize): Promise<Blob> =>
    callGraphApi<Blob>(`/v1.0/me/photos/${size}x${size}/$value`, accessToken, 'blob')

  const getCurrentUserPhotoDataUrl = async (size: PhotoSize): Promise<string | null> => {
    const photoBlob = await getCurrentUserPhoto(size)
    return await convertBlobToDataUrl(photoBlob)
  }

  return {
    getCurrentUserInfo,
    getCurrentUserPhoto,
    getCurrentUserPhotoDataUrl
  }
}

export const callGraphApi = <TRes>(url: string, accessToken: string, responseType: 'blob'|'text'|'json' = 'json'): Promise<TRes> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        if (responseType === 'json')
          resolve(JSON.parse(xhr.responseText))
        else
          resolve(xhr.response)
      } else {
        reject(xhr.statusText)
      }
    })

    xhr.addEventListener('error', () => reject(xhr.statusText))

    xhr.open('GET', 'https://graph.microsoft.com' + url, true)

    // IE doesn't support reponseType json so we deal with that manually :(
    xhr.responseType = responseType === 'json' ? 'text' : responseType

    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)

    xhr.send()
  })
}

const convertBlobToDataUrl = (blob: Blob): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () =>
      resolve(reader.result as string | null) // Note: ArrayBuffer isn't relevant here

    reader.onerror = reject

    reader.readAsDataURL(blob)
  })
}

export default createGraphClient